import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Page from "../components/Page";
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  }
}));

const Seasons = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [menuSeason, setMenuSeason] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetchSeasons();
  }, []);

   const handleClick = (season, event) => {
    setMenuSeason(season);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchSeasons = async () => {
    setLoading(true);
    try {
      const result = await api.get('/api/v1/seasons/');
      setSeasons(result);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setSeasons([]);
      console.error(e);
    }
  }

  const renderSeasons = () => {
    if (seasons.length === 0) {
      return (
        <Box my={3}>
          <Typography variant={'body1'} align={'center'}>There are currently no seasons</Typography>
        </Box>
      );
    }
    return (
      <>
        <List className={classes.list}>
          {seasons.map((season) => {
            return (
              <ListItem divider key={season.id} onClick={() => history.push(`/season-leaderboard/${season.id}`)}>
                <ListItemText primary={`${season.name}`} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={(e) => handleClick(season, e)}>
                    <MoreVertIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => history.push(`/season-leaderboard/${menuSeason.id}`)}>Leaderboard</MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <Page title={"Seasons"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading ?
            <LinearProgress />
            :
            <div>
              {renderSeasons()}
            </div>
          }
        </Grid>
      </Grid>
    </Page>
  );
};

export default Seasons;
