import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from '@material-ui/core/Chip';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import {Field, Form, Formik, useFormikContext} from 'formik';
import {Button, FormControlLabel, LinearProgress, Radio} from '@material-ui/core';
import {RadioGroup, Select, TextField} from 'formik-material-ui';
import {useAuth} from "../provider";
import * as api from "../api";
import Page from "../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const AddCompetitionSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a competition name.'),
  live_video_type: Yup.string(),
  facebook_live_url: Yup.string().url('Please enter a valid Facebook live URL'),
  youtube_video_id: Yup.string().length(11, 'The Youtube video ID must have 11 characters. eg. "OnCyxnbqUp0"'),
  drivers: Yup.array().length(16, 'Please select 16 drivers'),
  season: Yup.string(),
});

const AddEditCompetition = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [seasons, setSeasons] = useState(null);
  const classes = useStyles();
  const { state, handleLogin } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      fetchData();
    } else {
      fetchDriversAndSeasons();
    }
  }, []);

  const fetchDriversAndSeasons = async () => {
    setLoading(true);
    try {
      const drivers = await api.get(`/api/v1/drivers/`);
      const seasons = await api.get(`/api/v1/seasons/`);
      setDrivers(drivers);
      setSeasons(seasons);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const fetchDriversOnChange = async () => {
    api.get(`/api/v1/drivers/`)
      .then((response) => {
        setDrivers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const competition = await api.get(`/api/v1/competitions/${id}/`);
      const drivers = await api.get(`/api/v1/drivers/`);
      const seasons = await api.get(`/api/v1/seasons/`)
      setDrivers(drivers);
      setCompetition(competition);
      setSeasons(seasons);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getDriverLabel = (value) => {
    const driver = drivers.find(obj => {
      return obj.id === value
    });
    return `${driver.first_name} ${driver.last_name}`
  };

  return (
    <Page title={id ? "Edit competition" : "Add competition"}>
      {loading ?
        <LinearProgress />
        :
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                name: competition && competition.name ? competition.name : '',
                live_video_type: competition && competition.live_video_type ? competition.live_video_type : 'youtube',
                facebook_live_url: competition && competition.facebook_live_url ? competition.facebook_live_url : '',
                youtube_video_id: competition && competition.youtube_video_id ? competition.youtube_video_id : '',
                drivers: competition && competition.drivers ? competition.drivers : [],
                season: competition && competition.season ? competition.season : '',
              }}
              validationSchema={AddCompetitionSchema}
              onSubmit={(values, { setSubmitting, setErrors, errors }) => {
                if (id) {
                  api.patch(`/api/v1/competitions/${id}/`, values)
                    .then((response) => {
                      setSubmitting(false);
                      history.replace('/comp-admin/manage-competitions')
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    });
                } else {
                  api.post('/api/v1/competitions/', values)
                    .then((response) => {
                      setSubmitting(false);
                      history.replace('/comp-admin/manage-competitions')
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    });
                }
              }}
            >
              {({ submitForm, isSubmitting, errors, values, handleChange, setFieldValue }) => (
                <Form>
                  <Box mb={2}>
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      label="Competition name"
                      fullWidth
                    />
                  </Box>
                  <Box mb={2}>
                    <FormLabel htmlFor="live_video_type">Live video type</FormLabel>
                    <Field
                      component={RadioGroup}
                      name="live_video_type"
                    >
                      <FormControlLabel
                        value="facebook"
                        control={<Radio disabled={isSubmitting} />}
                        label="Facebook"
                        disabled={isSubmitting}
                      />
                     <FormControlLabel
                        value="youtube"
                        control={<Radio disabled={isSubmitting} />}
                        label="Youtube"
                        disabled={isSubmitting}
                      />
                    </Field>
                  </Box>
                  {values.live_video_type === 'facebook' &&
                    <Box mb={2}>
                      <Field
                        component={TextField}
                        name="facebook_live_url"
                        type="text"
                        label="Facebook video URL"
                        fullWidth
                        placeholder={'eg. https://www.facebook.com/62360768317/videos/3581807775376834'}
                      />
                    </Box>
                  }
                  {values.live_video_type === 'youtube' &&
                    <Box mb={2}>
                      <Field
                        component={TextField}
                        name="youtube_video_id"
                        type="text"
                        label="Youtube video ID"
                        fullWidth
                        placeholder={'eg. OnCyxnbqUp0'}
                      />
                    </Box>
                  }
                  <Box mb={2} mx={1}>
                    <FormControl fullWidth error={!!errors.drivers}>
                      <InputLabel htmlFor="drivers">Drivers</InputLabel>
                      <Field
                        component={Select}
                        name="drivers"
                        inputProps={{
                          id: 'drivers',
                          disabled: (competition && competition.state !== 'pending')
                        }}
                        multiple={true}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={getDriverLabel(value)}
                                className={classes.chip}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {drivers.map((driver) => (
                          <MenuItem
                            key={driver.id}
                            value={driver.id}
                            dense
                          >
                            {`${driver.first_name} ${driver.last_name}`}
                          </MenuItem>
                        ))}
                      </Field>
                      {errors.drivers &&
                        <FormHelperText error id="drivers-helper-text">{errors.drivers}</FormHelperText>
                      }
                    </FormControl>
                  </Box>
                  <Box mb={2} mx={1}>
                    <FormControl fullWidth error={!!errors.season}>
                      <InputLabel htmlFor="season">Season</InputLabel>
                      <Field
                        component={Select}
                        name="season"
                        inputProps={{
                          id: 'season',
                        }}
                      >
                        {seasons.map((season) => (
                          <MenuItem
                            key={season.id}
                            value={season.id}
                          >
                            {`${season.name}`}
                          </MenuItem>
                        ))}
                      </Field>
                      {errors.season &&
                        <FormHelperText error id="drivers-helper-text">{errors.season}</FormHelperText>
                      }
                    </FormControl>
                  </Box>
                  {isSubmitting && <LinearProgress />}
                  <Box mb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      }
    </Page>
  );
};

export default AddEditCompetition;
