import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import * as api from '../api';
import kirLogo from "../kir-logo.png";
import crown from "../crown.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#000000',
    minHeight: '100vh',
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  },
  battleBox: {
    border: '4px solid',
    borderColor: 'white',
  },
  knockedOutButton: {
    color: theme.palette.error.main,
  },
  winnerButton: {
    color: theme.palette.success.main,
  },
  crown: {
    position: 'absolute',
    top: -20,
    left: -40,
    transform: 'rotate(-35deg)'
  },
  whiteText: {
    color: 'white',
  },
  driverNumberRight: {
    fontSize: 40,
    paddingLeft: theme.spacing(2),
  },
  driverNumberLeft: {
    fontSize: 40,
    paddingRight: theme.spacing(2),
  }
}));

const TruckScreen = (props) => {
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    fetchCompetitionData();
    const interval = setInterval(() => {
      fetchCompetitionData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const fetchCompetitionData = async (init=false) => {
    if (init) {
      setLoading(true);
    }
    try {
      const result = await api.get(`/api/v1/competition-truck-screen/active/`);
      setCompetition(result);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setCompetition(null);
      console.error(e);
    }
  };

  const renderCompetitorOne = (battle) => {
    if (battle.label === 'bye') {
      return (
        <>
          <span className={classes.driverNumberLeft}>#{battle.winner.driver.driver_number}</span>
          {battle.winner.driver.first_name.substring(0, 1)}. {battle.winner.driver.last_name}
        </>
      );
    } else if (battle.winner) {
      return (
        <>
          <span className={classes.driverNumberLeft}>#{battle.winner.driver.driver_number}</span>
          {battle.winner.driver.first_name.substring(0, 1)}. {battle.winner.driver.last_name}
        </>
      );
    } else {
      return (
        <>
          <span className={classes.driverNumberLeft}>#{battle.competitor_one.driver.driver_number}</span>
          {battle.competitor_one.driver.first_name.substring(0, 1)}. {battle.competitor_one.driver.last_name}
        </>
      );
    }
  };

  const renderCompetitorTwo = (battle) => {
    if (battle.loser) {
      return (
        <>
          {battle.loser.driver.first_name.substring(0, 1)}. {battle.loser.driver.last_name}
          <span className={classes.driverNumberRight}>#{battle.loser.driver.driver_number}</span>
        </>
      );
    } else {
      return (
        <>
          {battle.competitor_two.driver.first_name.substring(0, 1)}. {battle.competitor_two.driver.last_name}
          <span className={classes.driverNumberRight}>#{battle.competitor_two.driver.driver_number}</span>
        </>
      );
    }
  };

  const renderCompetition = () => {
    if (!competition) {
      return (
        <Grid item xs={12}>
          <Typography align={'center'} variant={'body1'}>No currently active competition.</Typography>
        </Grid>
      );
    }
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mt={0}>
              <Typography variant="h2" gutterBottom align={"center"} style={{color: 'white'}}>
                {competition ? `${competition.name} - ${competition.state_display}` : ''}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {competition.battles.map((battle, index) => {
              return (
                <Box
                  className={classes.battleBox}
                  style={{width: '100%'}}
                  bgcolor={battle.active ? "info.light" : battle.winner ? 'grey.700' : 'black'}
                  mb={1}
                  p={1}
                  key={`${battle.id}`}
                >
                  <Box display="flex" justifyContent="center" m={1}>
                    <Typography variant={'h4'} style={battle.active ? {fontWeight: 'bold', color: 'white'} : {color: 'white'}}>
                     {battle.label === '1st_2nd' ? battle.label_display : battle.secondary_display_text_public}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" m={1} py={2}>
                    <div style={{position: 'relative'}}>
                      {battle.winner &&
                        <img
                          alt="crown"
                          src={crown}
                          width={'80'}
                          className={classes.crown}
                        />
                      }
                      <Typography
                        variant={'h1'}
                        className={battle.winner ? classes.winnerButton : classes.whiteText}
                        style={{fontWeight: 'bold'}}
                      >
                        {renderCompetitorOne(battle)}
                      </Typography>
                    </div>
                    {battle.label !== 'bye' &&
                    <>
                      <Box display="flex" justifyContent="center" alignSelf={'center'} m={0.5} px={1}>
                        <Typography variant={'h5'} style={{color: 'white'}}>
                          vs
                        </Typography>
                      </Box>
                      <Typography
                        variant={'h1'}
                        className={battle.loser ? classes.knockedOutButton : classes.whiteText}
                        style={{fontWeight: 'bold'}}
                      >
                        {renderCompetitorTwo(battle)}
                      </Typography>
                    </>
                    }
                  </Box>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box my={1} style={{textAlign: 'center'}}>
              <img alt="logo" src={kirLogo} width={'45%'} className={classes.logo} />
            </Box>
          </Grid>
          {renderCompetition()}
        </Grid>
      </Container>
    </div>
  );
};

export default TruckScreen;
