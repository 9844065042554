import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {useAuth} from "../provider";
import Page from "../components/Page";
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  }
}));

const ManageSeasons = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [menuSeason, setMenuSeason] = useState(null);
  const { getAuthState, state } = useAuth();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetchSeasons();
  }, []);

  const deleteSeason = async () => {
    try {
      await api._delete(`/api/v1/seasons/${menuSeason.id}`);
    }
    catch (e) {
      console.error(e);
    }
  };

  const fetchSeasons = async () => {
    setLoading(true);
    try {
      const result = await api.get('/api/v1/seasons/');
      setSeasons(result);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setSeasons([]);
      console.error(e);
    }
  }

  const handleDeleteClick = async () => {
    handleClose();
    handleModalClickOpen();
  };

  const handleDelete = async () => {
    setLoading(true);
    handleModalClose();
    await deleteSeason();
    fetchSeasons();
  };

  const handleClick = (season, event) => {
    setMenuSeason(season);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalClickOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Page title={"Manage seasons"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            fullWidth
            onClick={() => history.push('/comp-admin/add-season')}
          >
            Add season
          </Button>
        </Grid>

        <Grid item xs={12}>
          {loading ?
            <LinearProgress />
            :
            <>
              <List className={classes.list}>
                {seasons.map((season) => {
                  return (
                    <ListItem divider key={season.id}>
                      <ListItemText primary={`${season.name}`} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={(e) => handleClick(season, e)}>
                          <MoreVertIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => history.push(`/comp-admin/edit-season/${menuSeason.id}`)}>Edit</MenuItem>
                <MenuItem onClick={() => handleDeleteClick()}>Delete</MenuItem>
              </Menu>
            </>
          }
        </Grid>
      </Grid>
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default ManageSeasons;
