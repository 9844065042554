import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import {useAuth} from "../provider";
import * as api from "../api";
import Page from "../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const SignInSchema = Yup.object().shape({
   username: Yup.string().required('Please enter your username.'),
   password: Yup.string().required('Please enter your password'),
 });

const SignIn = () => {
  const { handleLogin } = useAuth();
  const history = useHistory();

  return (
    <Page title={"Sign in"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={SignInSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              api.post('/api/v1/api-token-auth/', values)
                .then((response) => {
                  handleLogin(response);
                  setSubmitting(false);
                  history.replace('/comp-admin/dashboard')
                })
                .catch((error) => {
                  if (error.non_field_errors) {
                    setErrors({username: 'Incorrect username or password.', password: 'Incorrect username or password.'})
                    setSubmitting(false);
                  }
                  setSubmitting(false);
                });
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Box my={1}>
                <Field
                  component={TextField}
                  name="username"
                  type="text"
                  label="Username"
                  fullWidth
                />
                </Box>
                <Box my={1}>
                <Field
                  component={TextField}
                  type="password"
                  label="Password"
                  name="password"
                  fullWidth
                />
                </Box>
                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>

        </Grid>
      </Grid>
    </Page>
  );
};

export default SignIn;
