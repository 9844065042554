import React, {useContext, useReducer} from 'react';
import axios from 'axios';
import reducer, {initialState, LOGGED_IN, LOGGED_OUT} from './reducer';

export const TOKEN_KEY = 'token';

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState || {});

  const getAuthState = async () => {
    try {
      let data = await getStorageData();
      if (data) await handleLogin(data);
      else await handleLogout(data);

      return data;
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleLogin = async (data) => {
    try {
      await setStorageData(data);
      setAuthorization(data.token);
      dispatch({type: LOGGED_IN});
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleLogout = async () => {
    try {
      await setStorageData();
      setAuthorization(null);
      dispatch({type: LOGGED_OUT});
    } catch (error) {
      throw new Error(error);
    }
  };

  const value = {
    state,
    getAuthState,
    handleLogin,
    handleLogout,
  };

  return (
    <AuthContext.Provider
      value={value}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);
export {AuthContext, useAuth}
export default AuthProvider;


export const setAuthorization = (token) => {
  if (!token) delete axios.defaults.headers.common["Authorization"];
  else axios.defaults.headers.common["Authorization"] = `Token ${token}`;
};

export const getStorageData = async () => {
  try {
    let token = await asyncLocalStorage.getItem(TOKEN_KEY);

    if (token !== null) return {token: token};
    else return null;

  } catch (error) {
    throw new Error(error)
  }
};

export const setStorageData = async (data) => {
  try {
    if (!data) {
      await asyncLocalStorage.removeItem(TOKEN_KEY);
    } else {
      let {token} = data;
      await asyncLocalStorage.setItem(TOKEN_KEY, token);
    }
  } catch (error) {
    throw new Error(error)
  }
};

const asyncLocalStorage = {
  setItem: async function (key, value) {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async function (key) {
    await null;
    return localStorage.getItem(key);
  },
  removeItem: async function (key) {
    await null;
    return localStorage.removeItem(key);
  }
};
