import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Typography from '@material-ui/core/Typography';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useAuth} from "../provider";
import Page from "../components/Page";
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  undoButton: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
       backgroundColor: theme.palette.error.light,
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  },
  completeBattle: {
    backgroundColor: theme.palette.warning.light
  },
  activeBattle: {
    backgroundColor: theme.palette.info.light
  }

}));

const ManageCompetition = (props) => {
  const { id } = props.match.params;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState(null);
  const [menuBattle, setMenuBattle] = useState(null);
  const [value, setValue] = React.useState('1');
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchCompetitionData(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchCompetitionData(false);
    }, 30000);
    return () => clearInterval(interval);
  }, [props.match.params.id]);

  const handleModalClickOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const fetchCompetitionData = async (init=false) => {
    if (init) {
      setLoading(true);
    }
    try {
      const result = await api.get(`/api/v1/competitions/${id}/`);
      setCompetition(result);
      if (init) {
        setValue(`${result.rounds.length}`)
      }
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setCompetition(null);
      console.error(e);
    }
  }

  const handleClick = (battle, event) => {
    setMenuBattle(battle);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleTransition = async () => {
    setLoading(true);
    try {
      await api.post(`/api/v1/competitions/${id}/transition_round/`);
      await fetchCompetitionData(true);
    }
    catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const handleUndoTransition = async () => {
    setLoading(true);
    handleModalClose();
    try {
      await api.post(`/api/v1/competitions/${id}/undo_transition_round/`);
      await fetchCompetitionData(true);
    }
    catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const toggleMenuBattleActive = async () => {
    handleClose();
    setLoading(true);
    try {
      await api.post(`/api/v1/competitions/${id}/toggle_battle_active/`, {battle_id: menuBattle.id});
      await fetchCompetitionData();
    }
    catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const setCompetitorAsWinner = async (competitor_id) => {
    handleClose();
    setLoading(true);
    try {
      await api.post(`/api/v1/competitions/${id}/set_battle_winner/`, {
        battle_id: menuBattle.id,
        competitor_id: competitor_id,
      });
      await fetchCompetitionData();
    }
    catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const unsetWinner = async () => {
    handleClose();
    setLoading(true);
    try {
      await api.post(`/api/v1/competitions/${id}/unset_battle_winner/`, {
        battle_id: menuBattle.id,
      });
      await fetchCompetitionData();
    }
    catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <Page title={competition ? `${competition.name} - ${competition.state_display}` : ''}>
      {loading ?
        <LinearProgress />
        :
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {competition.state !== 'complete' &&
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth
                onClick={() => handleTransition()}
                disabled={!competition.can_transition_state}
              >
                {competition.transition_button_text}
              </Button>
            }
            {competition.can_undo_transition &&
              <Button
                variant="contained"
                className={classes.undoButton}
                fullWidth
                onClick={() => handleModalClickOpen()}
                disabled={!competition.can_undo_transition}
              >
                {competition.undo_transition_button_text}
              </Button>
            }
            {competition.state !== 'complete' && !competition.can_transition_state &&
              <Typography variant={'p'}>
                Note: All battles must have a winner set in order to advance.
              </Typography>
            }
          </Grid>
          <Grid item xs={12}>
            {competition.rounds.length > 0 &&
              <TabContext value={value}>
                <AppBar position="static">
                  <TabList onChange={handleChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="on">
                    {competition.rounds.map((round, index) => {
                      return <Tab key={`${index + 1}`} label={round.round_display} value={`${index + 1}`}/>;
                    })}
                  </TabList>
                </AppBar>
                {competition.rounds.map((round, index) => {
                  return (
                    <TabPanel
                      value={`${index + 1}`}
                      key={index}
                      style={{paddingLeft: 0, paddingRight: 0}}
                    >
                      <List className={classes.list}>
                        {round.battles.map((battle) => {
                          return (
                            <ListItem
                              key={`battle-${battle.id}`}
                              divider
                              className={battle.active ? classes.activeBattle : battle.winner ? classes.completeBattle : {}}
                            >
                              <ListItemText
                                primary={battle.display_text}
                                secondary={battle.secondary_display_text}
                              />
                              {!round.complete && battle.label !== 'bye' &&
                                <ListItemSecondaryAction>
                                  <IconButton edge="end" onClick={(e) => handleClick(battle, e)}>
                                    <MoreVertIcon/>
                                  </IconButton>
                                </ListItemSecondaryAction>
                              }
                            </ListItem>
                          );
                        })}
                      </List>
                    </TabPanel>
                  );
                })}
              </TabContext>
            }
          </Grid>
          {menuBattle && menuBattle.label !== 'bye' &&
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {!menuBattle.winner &&
                <MenuItem key={'battle_active'} onClick={() => toggleMenuBattleActive()}>Toggle battle as active</MenuItem>
              }
              <MenuItem
                key={'set_competition_one_active'}
                onClick={() => setCompetitorAsWinner(menuBattle.competitor_one.id)}
              >
                Set {menuBattle.competitor_one.driver.first_name} {menuBattle.competitor_one.driver.last_name} as winner
              </MenuItem>
              <MenuItem
                key={'set_competition_two_active'}
                onClick={() => setCompetitorAsWinner(menuBattle.competitor_two.id)}
              >
                Set {menuBattle.competitor_two.driver.first_name} {menuBattle.competitor_two.driver.last_name} as winner
              </MenuItem>
              {menuBattle.winner &&
                <MenuItem key={'unset_winner'} onClick={() => unsetWinner()}>Unset winner</MenuItem>
              }
            </Menu>
          }
        </Grid>
      }
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to undo this round?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will lose any current round progress.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUndoTransition} color="primary" autoFocus>
            Undo
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default ManageCompetition;
