import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Page from "../components/Page";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  },
  battleBox: {
    border: '1px solid',
    borderColor: theme.palette.grey['400']
  }
}));

const SeasonLeaderboard = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    fetchSeasonData(true);
  }, [props.match.params.id]);

  const fetchSeasonData = async (init=false) => {
    if (init) {
      setLoading(true);
    }
    try {
      const result = await api.get(`/api/v1/seasons/${id}/season_leaderboard/`);
      setSeason(result);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setSeason(null);
      console.error(e);
    }
  }

  const renderSeason = () => {
    if (!season) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'body1'}>No season found.</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <>
        <Box my={3}>
          <Typography variant="h7" component="h3" gutterBottom align={"center"}>
            After {season.competition_count} competition rounds
          </Typography>
        </Box>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Rank</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Total Wins</TableCell>
                <TableCell>Total Loses</TableCell>
                {season.competition_columns.map((col, index) => (
                  <TableCell>{col}</TableCell>
                ))}
                <TableCell>Points Championship Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {season.drivers.map((driver, index) => (
                <TableRow key={`${driver.id}`}>
                  <TableCell>{driver.placed && `#${driver.placed}`}</TableCell>
                  <TableCell>{driver.first_name} {driver.last_name}</TableCell>
                  <TableCell>{driver.wins}</TableCell>
                  <TableCell>{driver.loses}</TableCell>
                  {driver.driver_rnd_data.map((points, index) => (
                    <TableCell>{points}</TableCell>
                  ))}
                  <TableCell>{driver.display_points}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <Page title={season ? `${season.name} - Leaderboard` : ''}>
      {loading ?
        <LinearProgress />
        :
        <>
          {renderSeason()}
        </>
      }
    </Page>
  );
};

export default SeasonLeaderboard;
