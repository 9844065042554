import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Typography from '@material-ui/core/Typography';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Page from "../components/Page";
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  },
  battleBox: {
    border: '1px solid',
    borderColor: theme.palette.grey['400']
  },
  knockedOutText: {
    color: theme.palette.error.main,
  },
  knockedOutButton: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
       backgroundColor: theme.palette.error.light,
    },
  },
  winnerButton: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
       backgroundColor: theme.palette.success.light,
    },
  }
}));

const CompetitionFullEvent = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState(null);
  const [value, setValue] = React.useState('1');
  const classes = useStyles();
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchCompetitionData(true);
  }, [props.match.params.id]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchCompetitionData();
    }, 30000);
    return () => clearInterval(interval);
  }, [props.match.params.id]);

  const fetchCompetitionData = async (init=false) => {
    if (init) {
      setLoading(true);
    }
    try {
      const result = await api.get(`/api/v1/competitions/${id}/`);
      setCompetition(result);
      if (init) {
        setValue(`${result.rounds.length}`)
      }
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setCompetition(null);
      console.error(e);
    }
  }

  const handleCompetitorOneClick = (battle) => {
    if (battle.label === 'bye' || battle.winner) {
      history.push(`/driver/${battle.winner.id}`);
    } else {
      history.push(`/driver/${battle.competitor_one.id}`);
    }
  };

  const handleCompetitorTwoClick = (battle) => {
    if (battle.loser) {
      history.push(`/driver/${battle.loser.id}`);
    } else {
      history.push(`/driver/${battle.competitor_two.id}`);
    }
  };

  const renderCompetitorOne = (battle) => {
    if (battle.label === 'bye') {
      return `#${battle.winner.driver.driver_number} - ${battle.winner.driver.first_name} ${battle.winner.driver.last_name} (${battle.winner.points})`;
    } else if (battle.winner) {
      return `#${battle.winner.driver.driver_number} - ${battle.winner.driver.first_name} ${battle.winner.driver.last_name} (W) (${battle.winner.points})`
    } else {
      return `#${battle.competitor_one.driver.driver_number} - ${battle.competitor_one.driver.first_name} ${battle.competitor_one.driver.last_name} (${battle.competitor_one.points})`
    }
  };

  const renderCompetitorTwo = (battle) => {
    if (battle.loser) {
      return `#${battle.loser.driver.driver_number} - ${battle.loser.driver.first_name} ${battle.loser.driver.last_name} (L) (${battle.loser.points})`
    } else {
      return `#${battle.competitor_two.driver.driver_number} - ${battle.competitor_two.driver.first_name} ${battle.competitor_two.driver.last_name} (${battle.competitor_two.points})`
    }
  };

  const renderKnockedOutText = (round, battle) => {
    if ((round.round === 'two' || round.round === 'three' || round.round === 'four') && battle.label === 'knockout' && battle.loser) {
      return (
        <Box alignSelf={'center'}>
          <Typography className={classes.knockedOutText} variant={'body2'}>
            KNOCKED OUT
          </Typography>
        </Box>
      );
    }
  };

  const renderCompetition = () => {
    if (!competition) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'body1'}>No competition found.</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {competition.rounds.length > 0 &&
            <TabContext value={value}>
              <AppBar position="static">
                <TabList onChange={handleChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="on">
                  {competition.rounds.map((round, index) => {
                    return <Tab key={`${index + 1}`} label={round.round_display} value={`${index + 1}`}/>;
                  })}
                </TabList>
              </AppBar>
              {competition.rounds.map((round, index) => {
                return (
                  <TabPanel
                    value={`${index + 1}`}
                    key={index}
                    style={{paddingLeft: 0, paddingRight: 0}}
                  >
                    {round.battles.map((battle, index) => {
                      return (
                        <Box
                          className={classes.battleBox}
                          style={{ width: '100%' }}
                          bgcolor={battle.active ? "info.light" : battle.winner ? 'grey.200' : 'white'}
                          mb={1}
                          p={1}
                          key={`${battle.id}`}
                          display="flex"
                          flexDirection="row"
                        >
                          <Box alignSelf={'center'}>
                            <Typography variant={'h5'}>
                              #{index+1}
                            </Typography>
                          </Box>
                          <Box display="flex" flexGrow={1} flexDirection="column">
                            <Box display="flex" justifyContent="center" m={0.5}>
                              <Typography variant={'body2'}>
                                {battle.secondary_display_text_public}
                              </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" m={0.5}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleCompetitorOneClick(battle)}
                                className={battle.winner ? classes.winnerButton : {}}
                              >
                                {renderCompetitorOne(battle)}
                              </Button>
                            </Box>
                            {battle.label !== 'bye' &&
                              <>
                                <Box display="flex" justifyContent="center" m={0.5}>
                                  <Typography variant={'body2'}>
                                    vs
                                  </Typography>
                                </Box>
                                <Box display="flex" alignSelf={'center'} flexDirection={'column'} justifyContent="center" m={0.5}>
                                  <Box>
                                    <Button
                                      variant="outlined"
                                      className={battle.loser ? classes.knockedOutButton : {}} color={'primary'}
                                      onClick={() => handleCompetitorTwoClick(battle)}
                                    >
                                      {renderCompetitorTwo(battle)}
                                    </Button>
                                  </Box>
                                  {renderKnockedOutText(round, battle)}
                                </Box>
                              </>
                            }
                          </Box>
                        </Box>
                      );
                    })}
                  </TabPanel>
                );
              })}
            </TabContext>
          }
        </Grid>
      </Grid>
    );
  };

  return (
    <Page title={competition ? `${competition.name} - ${competition.state_display}` : ''}>
      {loading ?
        <LinearProgress />
        :
        <>
          {renderCompetition()}
        </>
      }
    </Page>
  );
};

export default CompetitionFullEvent;
