import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from '@material-ui/core/Chip';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import {Field, Form, Formik, useFormikContext} from 'formik';
import {Button, FormControlLabel, LinearProgress, Radio} from '@material-ui/core';
import {RadioGroup, Select, TextField} from 'formik-material-ui';
import {useAuth} from "../provider";
import * as api from "../api";
import Page from "../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const AddSeasonSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a season name.'),
});

const AddEditSeason = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [season, setSeason] = useState(null);
  const classes = useStyles();
  const { state, handleLogin } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      fetchSeason();
    }
  }, []);

  const fetchSeason = async () => {
    setLoading(true);
    try {
      const season = await api.get(`/api/v1/seasons/${id}/`);
      setSeason(season);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Page title={id ? "Edit season" : "Add season"}>
      {loading ?
        <LinearProgress />
        :
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                name: season && season.name ? season.name : '',
              }}
              validationSchema={AddSeasonSchema}
              onSubmit={(values, { setSubmitting, setErrors, errors }) => {
                if (id) {
                  api.patch(`/api/v1/seasons/${id}/`, values)
                    .then((response) => {
                      setSubmitting(false);
                      history.replace('/comp-admin/manage-seasons')
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    });
                } else {
                  api.post('/api/v1/seasons/', values)
                    .then((response) => {
                      setSubmitting(false);
                      history.replace('/comp-admin/manage-seasons')
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    });
                }
              }}
            >
              {({ submitForm, isSubmitting, errors, values, handleChange, setFieldValue }) => (
                <Form>
                  <Box my={1}>
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      label="Season name"
                      fullWidth
                    />
                  </Box>
                  {isSubmitting && <LinearProgress />}
                  <Box my={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      }
    </Page>
  );
};

export default AddEditSeason;
