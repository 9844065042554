import React, {useEffect, useState} from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Home from './views/Home';
import TopBar from "./TopBar";
import NoMatch from "./views/NoMatch";
import SignIn from "./views/SignIn";
import Dashboard from "./views/Dashboard";
import ManageDrivers from "./views/ManageDrivers";
import AddEditDriver from "./views/AddEditDriver";
import ManageCompetitions from "./views/ManageCompetitions";
import AddEditCompetition from "./views/AddEditCompetition";
import ManageCompetition from "./views/ManageCompetition";
import CompetitionLive from "./views/CompetitionLive";
import CompetitionFullEvent from "./views/CompetitionFullEvent";
import Competitor from "./views/Competitor";
import CompetitionCompetitors from "./views/CompetitionCompetitors";
import CompetitionLeaderboard from "./views/CompetitionLeaderboard";
import TruckScreen from "./views/TruckScreen";
import ManageSeasons from "./views/ManageSeasons";
import AddEditSeason from "./views/AddEditSeason";
import Seasons from "./views/Seasons";
import SeasonLeaderboard from "./views/SeasonLeaderboard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const Routes = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path={"/comp-admin/sign-in"} component={SignIn} />
        <Route exact path={"/comp-admin/dashboard"} component={Dashboard} />
        <Route exact path={"/comp-admin/manage-drivers"} component={ManageDrivers} />
        <Route exact path={"/comp-admin/add-driver"} component={AddEditDriver} />
        <Route exact path={"/comp-admin/edit-driver/:id"} component={AddEditDriver} />
        <Route exact path={"/comp-admin/manage-competitions"} component={ManageCompetitions} />
        <Route exact path={"/comp-admin/add-competition"} component={AddEditCompetition} />
        <Route exact path={"/comp-admin/edit-competition/:id"} component={AddEditCompetition} />
        <Route exact path={"/comp-admin/manage-competition/:id"} component={ManageCompetition} />
        <Route exact path={"/comp-admin/manage-seasons"} component={ManageSeasons} />
        <Route exact path={"/comp-admin/add-season"} component={AddEditSeason} />
        <Route exact path={"/comp-admin/edit-season/:id"} component={AddEditSeason} />
        <Route exact path={"/seasons"} component={Seasons} />
        <Route exact path={"/season-leaderboard/:id"} component={SeasonLeaderboard} />
        <Route exact path={"/competition-live/:id"} component={CompetitionLive} />
        <Route exact path={"/competition-standings/:id"} component={CompetitionFullEvent} />
        <Route exact path={"/competition-drivers/:id"} component={CompetitionCompetitors} />
        <Route exact path={"/competition-leaderboard/:id"} component={CompetitionLeaderboard} />
        <Route exact path={"/driver/:id"} component={Competitor} />
        <Route exact path={"/truck-screen"} component={TruckScreen} />
        <Route component={NoMatch} />
      </Switch>
    </div>
  );
};

export default Routes;
