import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Page from "../components/Page";
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  }
}));

const ManageDrivers = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [menuDriver, setMenuDriver] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetchDrivers();
  }, []);

  const deleteDriver = async () => {
    try {
      await api._delete(`/api/v1/drivers/${menuDriver.id}`);
    }
    catch (e) {
      console.error(e);
    }
  };

  const fetchDrivers = async () => {
    setLoading(true);
    try {
      const result = await api.get('/api/v1/drivers/');
      setDrivers(result);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setDrivers([]);
      console.error(e);
    }
  }

  const handleDeleteClick = async () => {
    setLoading(true);
    handleClose();
    await deleteDriver();
    fetchDrivers();
  };

  const handleClick = (driver, event) => {
    setMenuDriver(driver);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Page title={"Manage drivers"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            fullWidth
            onClick={() => history.push('/comp-admin/add-driver')}
          >
            Add driver
          </Button>
        </Grid>

        <Grid item xs={12}>
          {loading ?
            <LinearProgress />
            :
            <>
              <List className={classes.list}>
                {drivers.map((driver) => {
                  return (
                    <ListItem key={driver.id} divider>
                      <ListItemText
                        primary={`#${driver.driver_number ? driver.driver_number : 'N/A'} - ${driver.first_name} ${driver.last_name}`}
                        secondary={`${driver.car} ${driver.mobile ? ` - ${driver.mobile}` : ''}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={(e) => handleClick(driver, e)}>
                          <MoreVertIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => history.push(`/comp-admin/edit-driver/${menuDriver.id}`)}>Edit</MenuItem>
                {menuDriver !== null && menuDriver.can_delete &&
                  <MenuItem onClick={() => handleDeleteClick()}>Delete</MenuItem>
                }
              </Menu>
            </>
          }
        </Grid>
      </Grid>
    </Page>
  );
};

export default ManageDrivers;
