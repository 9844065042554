import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {useAuth} from "./provider";
import kirLogo from "./kir-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: 10,
  },
  list: {
    width: 250,
    display: 'flex',
    flexDirection: 'column'
  },
  logo: {
    margin: 'auto',
    textAlign: 'center',
  },
  logoHorizontallyCenter: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const TopBar = () => {
  const classes = useStyles();
  const { state, handleLogout, getAuthState } = useAuth();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    getAuthState();
  }, []);

  const toggleDrawer = () => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };

  const handleLogoutPress = () => {
    handleLogout();
    history.replace('/');
  };

  return (
    <div className={classes.root}>
      <SwipeableDrawer
        open={drawerOpen}
        onClose={toggleDrawer()}
        onOpen={toggleDrawer()}
      >
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer()}
          onKeyDown={toggleDrawer()}
        >

          {state.isLoggedIn === true &&
            <List>
              <ListItem dense button key={'dashboard'} onClick={() => history.push('/comp-admin/dashboard')}>
                <ListItemText primary={'Admin dashboard'} />
              </ListItem>
              <ListItem dense button key={'manage-drivers'} onClick={() => history.push('/comp-admin/manage-drivers')}>
                <ListItemText primary={'Manage drivers'} />
              </ListItem>
              <ListItem dense button key={'manage-competitions'} onClick={() => history.push('/comp-admin/manage-competitions')}>
                <ListItemText primary={'Manage competitions'} />
              </ListItem>
              <ListItem dense button key={'manage-seasons'} onClick={() => history.push('/comp-admin/manage-seasons')}>
                <ListItemText primary={'Manage seasons'} />
              </ListItem>
              <ListItem dense button key={'truck-screen'} onClick={() => history.push('/truck-screen')}>
                <ListItemText primary={'Truck screen'} />
              </ListItem>
              <ListItem dense button key={'logout'} onClick={() => handleLogoutPress()}>
                <ListItemText primary={'Logout'} />
              </ListItem>
            </List>
          }

          <Divider />

          <List>
            <ListItem dense button key={'home'} onClick={() => history.push('/')}>
              <ListItemText primary={'Home'} />
            </ListItem>
            <ListItem dense button key={'kircom'} onClick={() => window.location.href = 'https://keepitreet.com'}>
              <ListItemText primary={'KeepitReet.com'} />
            </ListItem>
            <ListItem dense button key={'live'} onClick={() => history.push('/competition-live/active')}>
              <ListItemText primary={'Live'} />
            </ListItem>
            <ListItem dense button key={'seasons'} onClick={() => history.push('/seasons')}>
              <ListItemText primary={'Seasons'} />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem dense button key={'season-leaderboard'} onClick={() => history.push('/season-leaderboard/latest')}>
              <ListItemText primary={'Season leaderboard'} />
            </ListItem>
            <ListItem dense button key={'leaderboard'} onClick={() => history.push('/competition-leaderboard/latest')}>
              <ListItemText primary={'Competition leaderboard'} />
            </ListItem>
            <ListItem dense button key={'event-standings'} onClick={() => history.push('/competition-standings/latest')}>
              <ListItemText primary={'Event standings'} />
            </ListItem>
            <ListItem dense button key={'drivers'} onClick={() => history.push('/competition-drivers/latest')}>
              <ListItemText primary={'Drivers'} />
            </ListItem>
          </List>

          {state.isLoggedIn === false &&
            <>
              <Divider/>

              <List>
                <ListItem dense button key={'sign-in'} onClick={() => history.push('/comp-admin/sign-in')}>
                  <ListItemText primary={'Admin sign in'}/>
                </ListItem>
              </List>
            </>
          }

          <Divider/>

          <List>
            <ListItem dense button key={'battleist'} onClick={() => window.location.href = 'https://battleist.com'}>
              <ListItemText primary={'Powered by Battleist.com'} />
            </ListItem>
          </List>

        </div>
      </SwipeableDrawer>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer()}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logoHorizontallyCenter}>
            <img alt="logo" src={kirLogo} height={60} className={classes.logo} />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopBar;
