import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import YouTube from 'react-youtube';
import * as api from '../api';
import TopBar from "../TopBar";
import crown from "../crown.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  },
  battleBox: {
    border: '1px solid',
    borderColor: theme.palette.grey['400']
  },
  knockedOutButton: {
    color: theme.palette.error.main,
  },
  winnerButton: {
    color: theme.palette.success.main,
  },
  crown: {
    position: 'absolute',
    top: -8,
    left: -17,
    transform: 'rotate(-35deg)'
  },
  driverNumberRight: {
    fontSize: 18,
    paddingLeft: 5,
  },
  driverNumberLeft: {
    fontSize: 18,
    paddingRight: 5,
  },
  youtubeContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
    overflow: 'hidden',
    marginBottom: 10,
    '& iframe': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    }
  },
}));

const CompetitionLive = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState(null);
  const [facebookLiveUrl, setFacebookLiveUrl] = useState(null);
  const [youtubeVideoID, setYoutubeVideoID] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    setTimeout(window.FB.XFBML.parse, 500);
  }, [facebookLiveUrl]);

  useEffect(() => {
    fetchCompetitionData(true);
    setTimeout(window.FB.XFBML.parse, 500);
  }, [props.match.params.id]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchCompetitionData();
    }, 30000);
    return () => clearInterval(interval);
  }, [props.match.params.id]);

  const fetchCompetitionData = async (init=false) => {
    if (init) {
      setLoading(true);
    }
    try {
      const result = await api.get(`/api/v1/competition-live/${id}/`);
      setCompetition(result);
      if (result.live_video_type === 'facebook') {
        setFacebookLiveUrl(result.facebook_live_url);
        setYoutubeVideoID(null);
      } else {
        setFacebookLiveUrl(null);
        setYoutubeVideoID(result.youtube_video_id);
      }
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setCompetition(null);
      setFacebookLiveUrl(null);
      setYoutubeVideoID(null);
      console.error(e);
    }
  }

  const renderCompetitorOne = (battle) => {
    if (battle.label === 'bye') {
      return (
        <>
          <span className={classes.driverNumberLeft}>#{battle.winner.driver.driver_number}</span>
          {battle.winner.driver.first_name.substring(0, 1)}. {battle.winner.driver.last_name}
          <Typography variant={'body2'}>{battle.winner.driver.car}</Typography>
        </>
      );
    } else if (battle.winner) {
      return (
        <>
          <span className={classes.driverNumberLeft}>#{battle.winner.driver.driver_number}</span>
          {battle.winner.driver.first_name.substring(0, 1)}. {battle.winner.driver.last_name}
          <Typography variant={'body2'}>{battle.winner.driver.car}</Typography>
        </>
      );
    } else {
      return (
        <>
          <span className={classes.driverNumberLeft}>#{battle.competitor_one.driver.driver_number}</span>
          {battle.competitor_one.driver.first_name.substring(0, 1)}. {battle.competitor_one.driver.last_name}
          <Typography variant={'body2'}>{battle.competitor_one.driver.car}</Typography>
        </>
      );
    }
  };

  const renderCompetitorTwo = (battle) => {
    if (battle.loser) {
      return (
        <>
          {battle.loser.driver.first_name.substring(0, 1)}. {battle.loser.driver.last_name}
          <span className={classes.driverNumberRight}>#{battle.loser.driver.driver_number}</span>
          <Typography variant={'body2'}>{battle.loser.driver.car}</Typography>
        </>
      );
    } else {
      return (
        <>
          {battle.competitor_two.driver.first_name.substring(0, 1)}. {battle.competitor_two.driver.last_name}
          <span className={classes.driverNumberRight}>#{battle.competitor_two.driver.driver_number}</span>
          <Typography variant={'body2'}>{battle.competitor_two.driver.car}</Typography>
        </>
      );
    }
  };

  const renderCompetition = () => {
    if (!competition) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'body1'}>No competition found.</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{position: 'sticky', zIndex: 30, top: 0, backgroundColor: 'white'}}>
            <Grid item xs={12}>
              <Box mt={0}>
                <Typography variant="h5" component="h1" gutterBottom align={"center"}>
                  {competition ? `${competition.name} - ${competition.state_display}` : ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {facebookLiveUrl &&
                <Box style={{alignSelf: 'center', display: 'flex', flexDirection: 'column'}}>
                  <div
                    style={{alignSelf: 'center'}}
                    className="fb-video"
                    data-href={facebookLiveUrl}
                    data-width={window.innerWidth > 600 ? 600 : window.innerWidth}
                    data-show-text="false"
                    data-autoplay="true"
                  />
                </Box>
              }
              {youtubeVideoID &&
                <YouTube
                  videoId={youtubeVideoID}
                  opts={{playerVars: { autoplay: 1 } }}
                  className={classes.youtubeContainer}
                />
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {competition.battles.map((battle, index) => {
              return (
                <Box
                  className={classes.battleBox}
                  style={{ width: '100%' }}
                  bgcolor={battle.active ? "success.light" : battle.winner ? 'grey.300' : 'info.light'}
                  mb={1}
                  p={1}
                  key={`${battle.id}`}
                >
                  <Box display="flex" justifyContent="center" m={1}>
                    <Typography variant={'body2'}>
                      {battle.label === '1st_2nd' ? battle.label_display : battle.secondary_display_text_public}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" m={1} py={2}>
                    <div style={{position: 'relative'}}>
                      {battle.winner &&
                        <img
                          alt="crown"
                          src={crown}
                          width={'35'}
                          className={classes.crown}
                        />
                      }
                      <Typography
                        variant={'h5'}
                        className={battle.winner ? classes.winnerButton : {}}
                        style={{textAlign: battle.label === 'bye' ? 'center' : 'right'}}
                      >
                        {renderCompetitorOne(battle)}
                      </Typography>
                    </div>
                    {battle.label !== 'bye' &&
                      <>
                        <Box display="flex" justifyContent="center" alignSelf={'center'} m={0.5} px={1}>
                          <Typography variant={'body2'}>
                            vs
                          </Typography>
                        </Box>
                        <Typography
                          variant={'h5'}
                          className={battle.loser ? classes.knockedOutButton : {}}
                        >
                          {renderCompetitorTwo(battle)}
                        </Typography>
                      </>
                    }
                  </Box>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <TopBar/>
      <Container maxWidth="sm">
        {loading ?
          <LinearProgress />
          :
          <>
            {renderCompetition()}
          </>
        }
      </Container>
    </>
  );
};

export default CompetitionLive;
