import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Page from "../components/Page";
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  },
  battleBox: {
    border: '1px solid',
    borderColor: theme.palette.grey['400']
  },
  defeatedText: {
    color: theme.palette.success.main,
  },
  lostToText: {
    color: theme.palette.error.main,
  }
}));

const Competitor = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [competitor, setCompetitor] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    fetchCompetitorData();
  }, [props.match.params.id]);

  const fetchCompetitorData = async () => {
      setLoading(true);
    try {
      const result = await api.get(`/api/v1/competitors/${id}/`);
      setCompetitor(result);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setCompetitor(null);
      console.error(e);
    }
  }

  const renderCompetitor = () => {
    if (!competitor) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'body1'}>No competition found.</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography align={'center'} variant={'body1'}>{competitor.driver.car}</Typography>
        </Grid>
        <Grid item xs={12}>
          {competitor.battles.map((battle, index) => {
            return (
              <Box
                className={classes.battleBox}
                style={{ width: '100%' }}
                bgcolor={'white'}
                mb={1}
                p={1}
                key={`${battle.id}`}
              >
                <Box display="flex" justifyContent="flex-start" m={1}>
                  <Typography variant={'body2'}>
                    {battle.round}
                  </Typography>
                </Box>
                {!battle.bye_run ?
                  <Box display="flex" justifyContent="center" m={1}>
                    <Typography
                      variant={'h5'}
                      className={battle.result_text === 'Defeated' ? classes.defeatedText : classes.lostToText}
                    >
                      {battle.result_text}
                    </Typography>
                    <Typography variant={'h5'}>
                      &nbsp;{`- ${battle.opponent_name}`}
                    </Typography>
                  </Box>
                  :
                  <Box display="flex" justifyContent="center" m={1}>
                    <Typography variant={'h5'}>
                      Bye run - Through to final battle
                    </Typography>
                  </Box>
                }

              </Box>
            );
          })}
        </Grid>
        {competitor.result_text &&
          <Grid item xs={12}>
            <Typography variant={'h5'} align={'center'}>
              {competitor.result_text}
            </Typography>
          </Grid>
        }
      </Grid>
    );
  };

  return (
    <Page title={competitor ? `${competitor.driver.first_name} ${competitor.driver.last_name} - Results` : ''}>
      {loading ?
        <LinearProgress />
        :
        <>
          {renderCompetitor()}
        </>
      }
    </Page>
  );
};

export default Competitor;
