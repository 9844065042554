import axios from 'axios';

export const get = async (url) => {
  try {
      const res = await axios.get(url);
      return res.data;
  } catch (error) {
      throw handler(error);
  }
};

export const post = async (url, payload) => {
    try {
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw handler(error);
    }
};

export const put = async (url, payload) => {
    try {
        const res = await axios.put(url, payload);
        return res.data;
    } catch (error) {
        throw handler(error);
    }
};

export const patch = async (url, payload) => {
    try {
        const res = await axios.patch(url, payload);
        return res.data;
    } catch (error) {
        throw handler(error);
    }
};

export const _delete = async (url) => {
    try {
        const res = await axios.delete(url);
        return res.data;
    } catch (error) {
        throw handler(error);
    }
};

export const handler = (err) => {
    let error = err;
    error = err.response.data;
    return (error);
};
