import React from 'react';
import Page from "../components/Page";

const NoMatch = () => {
  return (
    <Page title={'404 Not found'} />
  );
};

export default NoMatch;
