import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import {Button, LinearProgress, FormControlLabel, Radio} from '@material-ui/core';
import {TextField, RadioGroup} from 'formik-material-ui';
import * as api from "../api";
import Page from "../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const phoneRegex = /^\+614[0-9]{8}$/;

const AddDriverSchema = Yup.object().shape({
  first_name: Yup.string().required('Please enter a first name.'),
  last_name: Yup.string().required('Please enter a last name.'),
  car: Yup.string().required('Please enter a car.'),
  mobile: Yup.string().matches(phoneRegex, 'Please enter a valid Australian mobile number in the format "+61401234567"'),
  driver_number: Yup.number().positive().integer().required('Please enter a driver number'),
});

const AddEditDriver = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(!!id);
  const [driver, setDriver] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      fetchDriver();
    }
  }, []);

  const fetchDriver = async () => {
    setLoading(true);
    api.get(`/api/v1/drivers/${id}/`)
      .then((response) => {
        setDriver(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Page title={id ? "Edit driver" : "Add driver"}>
      {loading ?
        <LinearProgress />
        :
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                first_name: driver && driver.first_name ? driver.first_name : '',
                last_name: driver && driver.last_name ? driver.last_name : '',
                car: driver && driver.car ? driver.car : '',
                mobile: driver && driver.mobile ? driver.mobile : '',
                driver_number: driver && driver.driver_number ? driver.driver_number : '',
              }}
              validationSchema={AddDriverSchema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                if (id) {
                  api.patch(`/api/v1/drivers/${id}/`, values)
                    .then((response) => {
                      setSubmitting(false);
                      history.replace('/comp-admin/manage-drivers')
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    });
                } else {
                  api.post('/api/v1/drivers/', values)
                    .then((response) => {
                      setSubmitting(false);
                      history.replace('/comp-admin/manage-drivers')
                    })
                    .catch((error) => {
                      setSubmitting(false);
                    });
                }
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <Box my={1}>
                    <Field
                      component={TextField}
                      name="first_name"
                      type="text"
                      label="First name"
                      fullWidth
                    />
                  </Box>
                  <Box my={1}>
                    <Field
                      component={TextField}
                      name="last_name"
                      type="text"
                      label="Last name"
                      fullWidth
                    />
                  </Box>
                  <Box my={1}>
                    <Field
                      component={TextField}
                      name="car"
                      type="text"
                      label="Car"
                      fullWidth
                    />
                  </Box>
                  <Box my={1}>
                    <Field
                      component={TextField}
                      name="mobile"
                      type="text"
                      label="Mobile"
                      fullWidth
                    />
                  </Box>
                  <Box my={1}>
                    <Field
                      component={TextField}
                      name="driver_number"
                      type="number"
                      label="Driver number"
                      fullWidth
                    />
                  </Box>
                  {isSubmitting && <LinearProgress />}
                  <Box my={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>

          </Grid>
        </Grid>
      }
    </Page>
  );
};

export default AddEditDriver;
