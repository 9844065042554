export const LOGGED_IN = `auth/LOGGED_IN`;
export const LOGGED_OUT = `auth/LOGGED_OUT`;

export const initialState = {
  isLoggedIn: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN: {
      return {...state, isLoggedIn: true};
    }
    case LOGGED_OUT: {
      return {...state, ...initialState};
    }
    default:
      return state;
  }
};

export default authReducer;
