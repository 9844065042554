import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Page from "../components/Page";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  },
  battleBox: {
    border: '1px solid',
    borderColor: theme.palette.grey['400']
  }
}));

const CompetitionLeaderboard = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    fetchCompetitionData(true);
  }, [props.match.params.id]);

  const fetchCompetitionData = async (init=false) => {
    if (init) {
      setLoading(true);
    }
    try {
      const result = await api.get(`/api/v1/competitions/${id}/competitor_leaderboard/`);
      setCompetition(result);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setCompetition(null);
      console.error(e);
    }
  }

  const renderCompetition = () => {
    if (!competition) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'body1'}>No competition found.</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Wins</TableCell>
              <TableCell>Loses</TableCell>
              <TableCell>Points</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {competition.competitors.map((competitor, index) => (
              <TableRow key={`${competitor.id}`}>
                <TableCell>{competitor.placed && `#${competitor.placed}`}</TableCell>
                <TableCell>{competitor.first_name} {competitor.last_name}</TableCell>
                <TableCell>{competitor.wins}</TableCell>
                <TableCell>{competitor.loses}</TableCell>
                <TableCell>{competitor.display_points}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Page title={competition ? `${competition.name} - Leaderboard` : ''}>
      {loading ?
        <LinearProgress />
        :
        <>
          {renderCompetition()}
        </>
      }
    </Page>
  );
};

export default CompetitionLeaderboard;
