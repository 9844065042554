import React from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {useAuth} from "../provider";
import Page from "../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { getAuthState, state } = useAuth();

  return (
    <Page title={"Dashboard"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            fullWidth
            onClick={() => history.push('/comp-admin/manage-drivers')}
          >
            Manage drivers
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            fullWidth
            onClick={() => history.push('/comp-admin/manage-competitions')}
          >
            Manage competitions
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            fullWidth
            onClick={() => history.push('/comp-admin/manage-seasons')}
          >
            Manage seasons
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Dashboard;
