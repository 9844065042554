import React from "react";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TopBar from "../TopBar";

const Page = (props) => {
  return (
    <>
      <TopBar />
      <Container maxWidth="sm">
        <Box my={3}>
          <Typography variant="h5" component="h1" gutterBottom align={"center"}>
            {props.title}
          </Typography>
        </Box>
        {props.children}
      </Container>
    </>
  );
};

export default Page;
