import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Page from "../components/Page";
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  },
  battleBox: {
    border: '1px solid',
    borderColor: theme.palette.grey['400']
  }
}));

const CompetitionCompetitors = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetchCompetitionData(true);
  }, [props.match.params.id]);

  const fetchCompetitionData = async (init=false) => {
    if (init) {
      setLoading(true);
    }
    try {
      const result = await api.get(`/api/v1/competitions/${id}/competitors/`);
      setCompetition(result);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setCompetition(null);
      console.error(e);
    }
  }

  const renderCompetition = () => {
    if (!competition) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography align={'center'} variant={'body1'}>No competition found.</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List className={classes.list}>
          {competition.competitors.map((competitor, index) => {
            return (
              <ListItem divider key={`${index}`} onClick={() => history.push(`/driver/${competitor.id}`)}>
                <ListItemText
                  primary={`#${competitor.driver_number ? competitor.driver_number : 'N/A'} - ${competitor.first_name} ${competitor.last_name} (${competitor.display_points})`}
                  secondary={competitor.car}
                />
              </ListItem>
            );
          })}
          </List>
        </Grid>
      </Grid>
    );
  };

  return (
    <Page title={competition ? `${competition.name} - Drivers` : ''}>
      {loading ?
        <LinearProgress />
        :
        <>
          {renderCompetition()}
        </>
      }
    </Page>
  );
};

export default CompetitionCompetitors;
